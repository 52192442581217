<template>
    <section>
        <ul class="solutions-list">
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/chlorine-dosing-metering-pump.html" class="img">
                <img
                  src="/uploads/image/20210106/15/chlorine-dosing-pump.jpg"
                  alt="Chlorine Dosing Pump"
                />
              </a>
              <a href="" class="item_title text_center">
                Chlorine Dosing Pump
              </a>
              <div class="item_summary text_center">
                In the water treatment industry, sterilization and disinfection
                are pivotal and indispensable links, among which the most
                representative means of disinfection ...
              </div>
              <a
                href="/chlorine-dosing-metering-pump.html"
                class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/epoxy-metering-pumps.html" class="img">
                <img
                  src="/uploads/image/20210106/15/epoxy-metering-pumps.jpg"
                  alt="Epoxy Metering Pumps"
                />
              </a>
              <a href="" class="item_title text_center">
                Epoxy Metering Pumps
              </a>
              <div class="item_summary text_center">
                In the water treatment industry, sterilization and disinfection
                are pivotal and indispensable links, among which the most
                representative means of disinfection...
              </div>
              <a href="/epoxy-metering-pumps.html" class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/saltwater-dosing-pump.html" class="img">
                <img
                  src="/uploads/image/20210105/18/saltwater-dosing-pump.jpg"
                  alt="Saltwater Dosing Pump"
                />
              </a>
              <a href="" class="item_title text_center">
                Saltwater Dosing Pump
              </a>
              <div class="item_summary text_center">
                The saltwater dosing pump can be used for the transportation of
                strong corrosive media such as sulfuric acid, hydrochloric acid
                and nitric acid.Characteristics Of Saltwater Dosing Pump:The
                saltwater d...
              </div>
              <a
                href="/saltwater-dosing-pump.html"
                class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/sulfuric-acid-metering-pump.html" class="img">
                <img
                  src="/uploads/image/20210105/17/sulfuric-acid-metering-pump_1609838125.jpg"
                  alt="Sulfuric Acid Metering Pump"
                />
              </a>
              <a href="" class="item_title text_center">
                Sulfuric Acid Metering Pump
              </a>
              <div class="item_summary text_center">
                Sulfuric acid is a kind of active binary inorganic strong acid,
                which can react with most metals. High-concentration sulfuric
                acid has strong water absorption and can be used as a
                dehydrating agent, c...
              </div>
              <a
                href="/sulfuric-acid-metering-pump.html"
                class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/micro-dosing-pump/" class="img">
                <img
                  src="/uploads/image/20210105/17/micro-metering-pump_1609838243.jpg"
                  alt="Micro Chemical Dosing Pump"
                />
              </a>
              <a href="" class="item_title text_center">
                Micro Chemical Dosing Pump
              </a>
              <div class="item_summary text_center">
                The requirement for the precision of gear wheel dosing pumps is
                very high in these areas, including the accuracy of the tooth
                surface of the gear, gears, cases, and the matching of gears and
                tooth tip...
              </div>
              <a href="/micro-dosing-pump/" class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
          <li class="product_item sizing col-md-6">
            <div class="part">
              <a href="/desalination-metering-pump.html" class="img">
                <img
                  src="/uploads/image/20210113/11/desalination-metering-pump.png"
                  alt="Desalination Metering Pump"
                />
              </a>
              <a href="" class="item_title text_center">
                Desalination Metering Pump
              </a>
              <div class="item_summary text_center">
                Metering pump is also called dosing pump or proportioning pump.
                It is a kind of special volume pump that can meet all kinds of
                strict technological process requirements. The flow can be
                step-less adju...
              </div>
              <a
                href="/desalination-metering-pump.html"
                class="item_href text_center"
                >Learn More</a
              >
            </div>
          </li>
        </ul>
    </section>
</template>
<script>
export default {
  title: "Solution",
}
</script>